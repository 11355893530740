import axios from "@/plugins/http.service.js";

export default {
  getdriversSummary(fdate, tdate, id) {
    const tenant_id = localStorage.getItem("cooriroo_tenant_id");

    var data = {
      fdate: fdate,
      tdate: tdate,
      id: id,
      tenant_id,
    };
    return axios.post(
      `driver/get-drivers-working-hours-by-id?tenant_id=${tenant_id}`,
      data
    );
  },
  sendDriversSummary(data) {
    return axios.post("driver/send-driver-finance-summary", data);
  },
  getdrivers() {
    const tenant_id = localStorage.getItem("cooriroo_tenant_id");

    return axios.post(`driver/get-drivers?tenant_id=${tenant_id}`);
  },
  getFinance() {
    return axios.post("settings/finance-email");
  },
};
