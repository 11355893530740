import axios from "@/plugins/http.service.js";

export default {
  GetContents() {
    return axios.post("content/get-contents");
  },
  save(id, title_en, content_en) {
    var data = {
      id: id,
      title_en: title_en,
      content_en: content_en,
    };
    return axios.post("content/save/", data);
  },
  transalte(id, title_de, content_de) {
    var data = {
      id: id,
      title_de: title_de,
      content_de: content_de,
    };
    return axios.post("content/translate/", data);
  },
};
