import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import 'material-design-icons-iconfont/dist/material-design-icons.css' // Ensure you are using css-loader
import { TiptapVuetifyPlugin } from 'tiptap-vuetify'
// don't forget to import CSS styles
import 'tiptap-vuetify/dist/main.css'
// Vuetify's CSS styles 
import 'vuetify/dist/vuetify.min.css'
const vuetify = new Vuetify()
Vue.use(Vuetify);
Vue.use(TiptapVuetifyPlugin, {
  // the next line is important! You need to provide the Vuetify Object to this place.
  vuetify, // same as "vuetify: vuetify"
  // optional, default to 'md' (default vuetify icons before v2.0.0)
  iconsGroup: 'md'
});
export default new Vuetify({
  icons: {
    iconfont: 'md', // default - only for display purposes

  },
  theme: {
    themes: {
      light: {
        black:'#333333',
        primary: '#f44336',
        secondary: '#b0bec5',
        red: '#f44336',
        accent: '#8c9eff',
        error: '#b71c1c',
        green: '#f44336',
        green1: '#5EBC01',
        orange: '#FBC853'
      },

    },
  },
});
