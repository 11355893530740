import axios from "@/plugins/http.service.js";

export default {
  gettenantadmins(tenantId) {
    return axios.get(`admin/get-admins?tenant_id=${tenantId}`);
  },

  add(id, fname, lname, password, email) {
    var data = {
      fname,
      lname,
      email,
      password,
      tenant_id: id,
    };
    return axios.post("admin/add-admin", data);
  },

  save(id, fname, lname, password, email) {
    var data = {
      id: id,
      fname,
      lname,
      email,
      password,
    };
    return axios.post("admin/save-admin", data);
  },

  archive(id) {
    var data = {
      id: id,
    };
    return axios.delete("admin/archive-admin", data);
  },
};
