import axios from "@/plugins/http.service.js";

export default {
  gettanks(fdate, tdate) {
    const tenant_id = localStorage.getItem("cooriroo_tenant_id");

    var data = {
      fdate: fdate,
      tdate: tdate,
    };
    return axios.post(
      `diesel-tank/get-diesel-tanks?tenant_id=${tenant_id}`,
      data
    );
  },
  getimages(action_id) {
    var data = {
      action_id: action_id,
    };
    return axios.post("diesel-tank/get-images", data);
  },
};
