import axios from "@/plugins/http.service.js";

export default {
  getDrivers() {
    const tenant_id = localStorage.getItem("cooriroo_tenant_id");

    return axios.post(`driver/get-drivers?tenant_id=${tenant_id}`);
  },
  getFines(fdate, tdate) {
    const tenant_id = localStorage.getItem("cooriroo_tenant_id");

    var data = {
      fdate: fdate,
      tdate: tdate,
    };
    return axios.post(`fine/get-fines?tenant_id=${tenant_id}`, data);
  },
  getTrips(driver_id) {
    var data = {
      driver_id: driver_id,
    };
    return axios.post("trip/get-all-trips-filtred", data);
  },
  getWarehouses() {
    const tenant_id = localStorage.getItem("cooriroo_tenant_id");

    return axios.post(`warehouse/get-warehouse?tenant_id=${tenant_id}`);
  },
  add(driver_id, trip_id, amount, description, date, status) {
    const tenant_id = localStorage.getItem("cooriroo_tenant_id");

    var data = {
      driver_id: driver_id,
      trip_id: trip_id,
      amount: amount,
      description: description,
      date: date,
      status: status,
      tenant_id,
    };
    return axios.post("fine/add", data);
  },
  save(id, driver_id, trip_id, amount, description, date, status) {
    const tenant_id = localStorage.getItem("cooriroo_tenant_id");

    var data = {
      id: id,
      driver_id: driver_id,
      trip_id: trip_id,
      amount: amount,
      description: description,
      date: date,
      status: status,
      tenant_id,
    };
    return axios.post("fine/save", data);
  },

  archive(id) {
    var data = {
      id: id,
    };
    return axios.post("fine/archive", data);
  },
};
