import api from "./api.js";
import destinationApi from "../destination/api.js";

export default {
  data: () => ({
    valid: false,
    dialog: false,
    selectedObject: { id: -1 },
    loading: true,
    deleteDialog: false,
    search: "",
    tabs: null,
    driverDisable: true,
    selectedItem: 1,
    snackbar: false,
    clicked: false,
    text: "",
    id: null,
    info: [],
    textProps: {
      "v-model": "msg",
    },
    dateProps: {
      color: "primary",
    },
    timeProps: {
      format: "24hr",
      color: "primary",

      landscape: true,
    },
    NormalRules: [(v) => !!v || "Field is required"],
    types: [],
    selected_start_date: null,
    selected_driver: null,
    selected_warehouse: null,
    selected_type: null,
    start_date: null,
    data: [],
    pictures: [],
    type: null,
    myCurrentData: [],
    warehouses: [],
    drivers: [],
    infoDialog: false,
    fdate: null,
    tdate: null,
    note: "",
    destinations: [],
    destination_id: null,
    place: { name: "", lat: null, lon: null },
    // old_destination: false,
    // old_destination_id: "",
    current_destination: "",
    fmenu: false,
    tmenu: false,
    show1: false,
    json_fields: {
      Id: "id",
      "Driver Name": "driver.fullname",
      Warehouse: "warehouse.title",
      Type: "type.title",
      Status: "flag",
      "Start At": "start_date",
      "Picked On": "started_date",
      "Delivered On": "ended_date",
      "Nb of Accidents": "accidents.length",
      "Nb of Fines": "fines.length",
    },
    headers: [
      {
        text: "Driver Name",
        value: "driver.fullname",
        align: "center",
      },
      {
        text: "Origin",
        value: "warehouse.title",
        align: "center",
      },
      {
        text: "Type",
        value: "type0.title",
        align: "center",
      },
      {
        text: "Status",
        value: "flag",
        align: "center",
      },
      {
        text: "Created On",
        value: "created_date",
        align: "center",
      },
      {
        text: "Start Date",
        value: "start_date",
        align: "center",
      },
      {
        text: "Picked On",
        value: "started_date",
        align: "center",
      },
      {
        text: "Delivered On",
        value: "ended_date",
        align: "center",
      },
      {
        text: "Accident(s)",
        value: "accidents",
        align: "center",
      },
      {
        text: "Fine(s)",
        value: "fines",
        align: "center",
      },
      {
        text: "Actions",
        value: "actions",
        align: "center",
      },
    ],
  }),
  mounted() {
    api
      .gettypes()
      .then((res) => {
        // this.loading = false;
        if (res.data.responseCode === 1) {
          this.types = res.data.data;
        }
      })
      .catch((error) => {
        this.error = error;
        // this.loading = false;
        this.errorDialog = true;
      });
    var myCurrentDate = new Date();
    var myFutureDate = new Date();

    this.fdate = myCurrentDate.toISOString().substr(0, 10);
    this.tdate = myFutureDate.toISOString().substr(0, 10);

    api
      .getwarehouses()
      .then((res) => {
        if (res.data.responseCode === 1) {
          res.data.data.forEach((element) => {
            let w = { id: element.id, title: element.title };
            this.warehouses.push(w);
          });
        }
      })
      .catch((error) => {
        this.text = error;
        this.snackbar = true;
        // this.loading = false;
        this.errorDialog = true;
      });

    this.fetch();
  },
  methods: {
    async fetch() {
      this.data = [];
      await api
        .gettrips(this.fdate, this.tdate)
        .then((res) => {
          if (res.data.responseCode === 1) {
            res.data.data.forEach((element) => {
              element.driver =
                element.driver != null
                  ? {
                      fullname:
                        element.driver.fname + " " + element.driver.lname,
                      id: element.driver.id,
                    }
                  : { fullname: "N" + "/" + "A", id: -1 };
              element.warehouse = {
                title: element.warehouse.title,
                id: element.warehouse.id,
              };
              element.flag =
                element.flag == 0
                  ? "Not Picked"
                  : element.ended_date
                  ? "Delivered"
                  : "Picked";
              if (element.started_date != null) {
                if (
                  this.convertUTCDateToLocalDate(element.started_date) >
                  this.convertUTCDateToLocalDate(element.start_date)
                ) {
                  // Late
                  element.status = 1;
                } else {
                  element.status = 2; // Early
                }
              } else {
                element.status = 0; // Not yet
              }

              element.started_date = this.convertUTCDateToLocalDate(
                element.started_date
              );
              element.created_date = this.convertUTCDateToLocalDate(
                element.created_date
              );
              element.start_date = this.convertUTCDateToLocalDate(
                element.start_date
              );
              element.ended_date = this.convertUTCDateToLocalDate(
                element.ended_date
              );

              this.data.push(element);
            });
          }
        })
        .catch((error) => {
          this.text = error;
          this.snackbar = true;
          this.loading = false;
          this.errorDialog = true;
        });

      await destinationApi.getdestinations().then((res) => {
        if (res.data.responseCode === 1) {
          this.destinations = res.data.data;
        }
      });

      this.loading = false;
    },
    getFiltered(e) {
      this.myCurrentData = e;
      //output the filtered items
    },
    back() {
      this.$nextTick(() => {
        this.tabs = "tab-" + 1;
      });
    },
    show(item) {
      this.pictures = [];
      item.pictures.forEach((element) => {
        switch (this.type) {
          case 0:
            this.pictures.push({
              src: this.$imgurl + "fines/" + element.title,
            });
            break;
          case 1:
            this.pictures.push({
              src: this.$imgurl + "accidents/" + element.title,
            });
            break;
          default:
            break;
        }
      });
      this.$nextTick(() => {
        this.tabs = "tab-" + 2;
      });
    },
    focus(type, info) {
      this.$nextTick(() => {
        this.tabs = "tab-" + 1;
      });
      this.info = [];
      switch (type) {
        case 0: //Fines
          this.type = type;
          this.info = info;
          break;
        case 1: // Accidents
          this.type = type;
          this.info = info;
          break;
        default:
          break;
      }
      this.infoDialog = true;
    },
    convertUTCDateToLocalDate(utcDate) {
      if (utcDate) {
        const date = new Date(utcDate);
        var newDate = new Date(
          date.getTime() + date.getTimezoneOffset() * 60 * 1000
        );

        var offset = date.getTimezoneOffset() / 60;
        var hours = date.getHours();

        newDate.setHours(hours - offset);

        return newDate.toLocaleString();
      }
    },
    convertToUTCDate(date) {
      const localDate = new Date();
      const utcDate = new Date(date ? date : localDate);
      const utcDateTime = new Date(utcDate.toISOString());

      // Get date components
      const year = utcDateTime.getUTCFullYear();
      const month = utcDateTime.getUTCMonth() + 1; // Months are zero-indexed
      const day = utcDateTime.getUTCDate();
      const hours = utcDateTime.getUTCHours();
      const minutes = utcDateTime.getUTCMinutes();
      const seconds = utcDateTime.getUTCSeconds();

      // Format the date
      const formattedDate = `${year}-${this.pad(month)}-${this.pad(
        day
      )} ${this.pad(hours)}:${this.pad(minutes)}:${this.pad(seconds)}`;

      return formattedDate;
    },
    pad(number) {
      if (number < 10) {
        return "0" + number;
      }
      return number;
    },
    onDelete(item) {
      this.selectedObject = item;
      this.deleteDialog = true;
    },
    async agreeOnDelete() {
      this.clicked = true;

      this.loading = true;
      await api
        .archive(this.selectedObject.id)
        .then((res) => {
          this.loading = false;
          if (res.data.responseCode === 1) {
            this.deleteDialog = false;
            this.clicked = false;
            var index = this.data.indexOf(this.selectedObject);
            this.$delete(this.data, index);
            this.text = "Trip Deleted Succesfully";
            this.snackbar = true;
          } else {
            console.log(res.data.responseMessage);
          }
        })
        .catch((error) => {
          this.loading = false;
          console.log(error);
          this.errorDialog = true;
          this.deleteDialog = false;
          this.clicked = false;
        });
    },
    async showDialog(item) {
      this.valid = true;
      if (item == null) {
        this.selectedObject = { id: -1 };
        this.selected_driver = null;
        this.selected_warehouse = null;
        this.type = null;
        this.id = null;
        this.selected_start_date = new Date(Date.now());
      } else {
        this.selectedObject = item;
        this.selected_warehouse = {
          id: parseInt(item.warehouse.id),
          title: item.warehouse.title,
        };

        this.selected_driver = {
          id: parseInt(item.driver.id),
          fullname: item.driver.fullname,
        };

        this.selected_type = {
          id: parseInt(item.type0.id),
          title: item.type0.title,
        };

        this.id = item.id;
        this.selected_start_date = new Date(item.start_date);
        this.note = item.note;
        this.destination_id = item.destination_id;
      }

      this.dialog = true;
    },
    async save() {
      this.clicked = true;

      if (!this.$refs.form.validate()) return false;
      this.loading = true;

      if (this.selectedObject.id != -1) {
        await api
          .save(
            this.id,
            this.selected_driver.id,
            this.selected_warehouse.id,
            this.selected_type.id,
            this.convertToUTCDate(this.selected_start_date),
            this.note,
            this.destination_id,
            this.place.lat,
            this.place.lon,
            this.place.name
          )
          .then((res) => {
            this.loading = false;
            this.dialog = false;
            this.clicked = false;
            if (res.data.responseCode === 1) {
              var index = this.data.indexOf(this.selectedObject);
              this.data[index].driver = {
                id: this.selected_driver.id,
                fullname: this.selected_driver.fullname,
              };
              this.data[index].warehouse = {
                id: this.selected_warehouse.id,
                title: this.selected_warehouse.title,
              };
              this.data[index].type0 = this.selected_type;
              this.data[index].start_date = this.convertUTCDateToLocalDate(
                this.selected_start_date
              );

              this.data[index].started_date = this.convertUTCDateToLocalDate(
                this.data[index].started_date
              );
              this.data[index].created_date = this.convertUTCDateToLocalDate(
                this.data[index].created_date
              );
              this.data[index].start_date = this.convertUTCDateToLocalDate(
                this.data[index].start_date
              );
              this.data[index].ended_date = this.convertUTCDateToLocalDate(
                this.data[index].ended_date
              );

              this.text = "Trip updated successfully.";
              this.snackbar = true;
            } else {
              this.text = res.data.responseMessage;
              this.snackbar = true;
            }
          })
          .catch((error) => {
            this.text = error;
            this.snackbar = true;
            this.loading = false;
            this.dialog = false;
          });
      } else {
        await api
          .add(
            this.selected_driver.id,
            this.selected_warehouse.id,
            this.selected_type.id,
            this.convertToUTCDate(this.selected_start_date),
            this.note,
            this.destination_id,
            this.place.lat,
            this.place.lon,
            this.place.name
          )
          .then((res) => {
            this.loading = false;

            this.dialog = false;
            this.clicked = false;
            if (res.data.responseCode === 1) {
              this.data.push({
                id: res.data.data.id,
                driver: {
                  id: res.data.data.driver_id,
                  fullname: this.selected_driver.fullname,
                },
                warehouse: {
                  id: res.data.data.warehouse_id,
                  title: this.selected_warehouse.title,
                },
                type0: this.selected_type,
                start_date: this.convertUTCDateToLocalDate(
                  this.selected_start_date
                ),
                flag: "Not Picked",
                created_date: this.created_date,
                ended_date: null,
                started_date: null,
                accidents: [],
                fines: [],
              });
              this.text = "Trip added successfully.";
              this.snackbar = true;
            } else {
              this.text = res.data.responseMessage;
              this.snackbar = true;
            }
          })
          .catch((error) => {
            this.text = error;
            this.snackbar = true;
            this.loading = false;
            this.dialog = false;
            this.clicked = false;
          });
      }

      this.selected_type = null;
      this.destination_id = null;
      this.note = "";
      this.place = { name: "", lat: null, lon: null };
    },
    onPlaceSelected(place) {
      console.log("Selected place:", place);
      const latitude = place.geometry.location.lat();
      const longitude = place.geometry.location.lng();
      this.place = { name: place.name, lat: latitude, lon: longitude };
    },
    onPlaceInputChange(place) {
      this.place.name = place;
      this.place.lat = null;
      this.place.lon = null;

      this.selectDestinationDisabled = true;
      this.destination_id = null;
    },
    closeDialog() {
      this.dialog = false;
      this.destination_id = null;
      this.place = { name: "", lat: 1, lon: 1 };
      this.selected_driver = null;
      this.selected_warehouse = null;
      this.selected_type = null;
      this.note = "";
    },
  },

  watch: {
    selected_start_date: function() {
      api
        .getdrivers()
        .then((res) => {
          this.drivers = [];
          this.driverDisable = true;

          if (res.data.responseCode === 1) {
            this.driverDisable = false;

            res.data.data.forEach((element) => {
              if (element.archived === 1) return;
              let d = {
                id: parseInt(element.id),
                fullname: element.fname + " " + element.lname,
              };
              this.drivers.push(d);
            });
          }
        })
        .catch((error) => {
          this.text = error;
          this.snackbar = true;
          this.loading = false;
          this.errorDialog = true;
        });
    },
  },
  computed: {
    Column() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs": {
          return 12;
        }

        case "sm": {
          return 4;
        }

        case "md": {
          return 4;
        }
        case "lg": {
          return 4;
        }
        case "xl": {
          return 4;
        }
      }
    },
    isDestinationDisabled() {
      return this.place.name !== "";
    },
    isGoogleDisabled() {
      return this.destination_id !== null;
    },
  },
};
