import axios from "@/plugins/http.service.js";

export default {
  gettypes() {
    const tenant_id = localStorage.getItem("cooriroo_tenant_id");

    return axios.post(`type/get-types?tenant_id=${tenant_id}`);
  },
  gettrips(fdate, tdate) {
    const tenant_id = localStorage.getItem("cooriroo_tenant_id");

    var data = {
      fdate: fdate,
      tdate: tdate,
      tenant_id,
    };
    return axios.post("trip/get-all-trips", data);
  },
  add(
    driver_id,
    warehouse_id,
    type,
    start_date,
    note,
    destination_id,
    lat,
    lon,
    name
  ) {
    var data = {
      driver_id: driver_id,
      warehouse_id: warehouse_id,
      type: type,
      start_date: start_date,
      note,
      destination_id,
      destination_lat: lat,
      destination_lon: lon,
      destination_name: name,
    };
    return axios.post("trip/add-trip", data);
  },
  save(
    id,
    driver_id,
    warehouse_id,
    type,
    start_date,
    note,
    destination_id,
    lat,
    lon,
    name
  ) {
    var data = {
      id: id,
      driver_id: driver_id,
      warehouse_id: warehouse_id,
      type: type,
      start_date: start_date,
      note,
      destination_id,
      destination_lat: lat,
      destination_lon: lon,
      destination_name: name,
    };
    return axios.post("trip/save", data);
  },
  getdrivers() {
    const tenant_id = localStorage.getItem("cooriroo_tenant_id");

    return axios.get(`driver/get-drivers?tenant_id=${tenant_id}`);
  },
  archive(id) {
    var data = {
      id: id,
    };
    return axios.post("trip/archive", data);
  },

  getwarehouses() {
    const tenant_id = localStorage.getItem("cooriroo_tenant_id");

    return axios.post(`warehouse/get-warehouse?tenant_id=${tenant_id}`);
  },
};
