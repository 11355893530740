<!-- MainLayout -->
<template>
  <div id="parentx">
    <v-app id="inspire">
      <v-app-bar
        clipped-left
        elevation="0"
        app
        style="border-bottom: 2px solid #fbc853; background: white"
      >
        <v-app-bar-nav-icon
          class="red my-5"
          app
          v-if="mobileView"
          @click.stop="drawer = !drawer"
        ></v-app-bar-nav-icon>

        <v-list class="pa-0 mr-10">
          <v-list-item class="transparent">
            <v-list-item-action v-if="loggedin" style="margin: 0">
              <router-link class="" to="/">
                <div class="mainlogocontainer">
                  <img
                    style="
                      border-style: none;
                      height: 100%;  
                      width: 100%;   
                      vertical-align: middle;
                      padding-top: 5px;
                      padding-bottom: 5px;
                    "
                    alt="Vue logo"
                    class="logo"
                    src="../assets/images/logo.svg"
                  />
                </div>
              </router-link>
            </v-list-item-action>
          </v-list-item>
        </v-list>

        <v-toolbar-title style="margin-left: -40px"
          >COORIROO ADMINISTRATION</v-toolbar-title
        >

        <v-spacer></v-spacer>
        <div>
          <v-menu offset-y>
            <template v-slot:activator="{ on }">
              <v-btn plain color="#000" text v-on="on">
                <div
                  v-if="!mobileView"
                  right
                  ml-5
                  class="defont decol bold lower"
                >
                  {{ email }}
                </div>
                <v-icon small>expand_more</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item
                class="onhover"
                v-for="(item, index) in dropdown_User"
                :key="index"
                :to="item.to"
              >
                <v-list-item-title
                  v-if="!mobileView"
                  @click="logout"
                  class="menucolorred alleft"
                  color="#6A6A6A"
                  >{{ item.title }}</v-list-item-title
                >
                <v-list-item-title
                  v-else
                  @click="logout"
                  class="menucolorred alleft"
                  color="#6A6A6A"
                  >{{ item.title + " from " + email }}</v-list-item-title
                >
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
      </v-app-bar>

      <v-navigation-drawer
        ref="asdasdl"
        v-model="drawer"
        clipped
        :right="toright"
        :mini-variant="mini"
        app
        :mini-variant-width="minWidth"
      >
        <v-list class="pt-0">
          <div :key="index" v-for="(item, index) in items">
            <v-list-item
              class="onhover"
              exact-active-class="selectedrouter"
              v-if="!item.children"
              :key="item.title"
              :to="item.to"
            >
              <v-list-item-action>
                <v-icon>{{ item.icon }}</v-icon>
              </v-list-item-action>
              <v-list-item-title class="alleft black--text">{{
                item.title
              }}</v-list-item-title>
            </v-list-item>

            <v-list-group
              exact-active-class="selectedrouter"
              v-if="item.children"
              :key="item.title"
              :prepend-icon="item.icon"
              no-action
            >
              <template v-slot:activator class="grouponhover">
                <v-list-item-content>
                  <v-list-item-title v-text="item.title"></v-list-item-title>
                </v-list-item-content>
              </template>

              <v-list-item
                class="onhover"
                exact-active-class="selectedrouter"
                v-for="child in item.children"
                :key="child.title"
                :to="child.to"
              >
                <v-list-item-action>
                  <v-icon>{{ child.icon }}</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title v-text="child.title"></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-group>
          </div>
          <v-container>
            <v-layout justify-center>
              <v-btn class="nohover" color="" text @click.stop="mini = !mini">
                <div v-if="mini">
                  <img
                    class="titleimg"
                    :style="firststyle"
                    alt="Vue logo"
                    src="../assets/images/expandi.svg"
                  />
                </div>
                <div v-else>
                  <img
                    :style="secondstyle"
                    class="titleimg"
                    alt="Vue logo"
                    src="../assets/images/expandi.svg"
                  />
                </div>
              </v-btn>
            </v-layout>
          </v-container>
        </v-list>
      </v-navigation-drawer>
      <v-main app>
        <router-view :key="$route.fullPath"></router-view>
      </v-main>
    </v-app>
  </div>
</template>
<script>
export default {
  name: "mainlayout",
  data() {
    return {
      email: localStorage.getItem("cooriroo_admin_email"),
      dropdown_User: [{ title: "Logout", to: "/" }],
      drawer: true,
      mini: true,
      toright: false,
      mobileView: false,
      fullname: "Admin",
      loggedin: false,
      items: [],
      firststyle: "color:red",
      secondstyle: "transform: scaleX(-1);filter: FlipH; ;",
      minWidth: 80,
    };
  },
  mounted() {
    this.onResize(null);
    this.loggedin = true;

    this.items = [
      {
        title: "Dashboard",
        icon: "dashboard",
        to: "/dashboard",
      },
      {
        title: "Vehicles",
        icon: "directions_bus",
        to: "/vehicles",
      },
      {
        title: "Drivers",
        icon: "person_search",
        to: "/drivers",
      },
      {
        title: "Origins",
        icon: "apartment",
        to: "/origins",
      },
      {
        title: "Destinations",
        icon: "place",
        to: "/destinations",
      },
      {
        title: "Trips",
        icon: "loyalty",
        to: "/trips",
      },
      {
        title: "Trip Types",
        icon: "format_list_bulleted",
        to: "/trip-types",
      },
      {
        title: "Refuel",
        icon: "local_gas_station",
        to: "/refuel",
      },
      {
        title: "Accidents",
        icon: "bus_alert",
        to: "/accidents",
      },
      {
        title: "Fines",
        icon: "request_quote",
        to: "/fines",
      },
      {
        title: "Reports",
        icon: "insert_chart",
        children: [
          {
            title: "Map",
            icon: "map",
            to: "/map",
          },
          {
            title: "Driver Summary",
            icon: "description",
            to: "/driver-summary",
          },
          {
            title: "Finance Summary",
            icon: "euro",
            to: "/finance-summary",
          },
          {
            title: "Logs",
            icon: "source",
            to: "/logs",
          },
        ],
      },
      {
        title: "Change Vehicle Requests",
        icon: "sync_alt",
        to: "/change-vehicle-requests",
      },
      {
        title: "Contents",
        icon: "subject",
        to: "/contents",
      },
      {
        title: "Admins",
        icon: "admin_panel_settings",
        to: "/admins",
      },

      {
        title: "Push Notifications",
        icon: "notifications_none",
        to: "/push-notifications",
      },
    ];

    if (localStorage.getItem("is_super_admin") == 1) {
      this.items.push({
        title: "Super Admins",
        icon: "admin_panel_settings",
        to: "/super-admins",
      });
    }
  },
  created() {
    document.addEventListener("resize", this.onResize);
  },
  destroyed() {
    document.removeEventListener("resize", this.onResize);
  },

  methods: {
    logout: function() {
      localStorage.removeItem("cooriroo_admin_id");
      localStorage.removeItem("cooriroo_tenant_id");
      localStorage.removeItem("cooriroo_admin_email");
      localStorage.removeItem("is_super_admin");
    },

    onResize() {
      this.minWidth = "80";
      if (window.innerWidth >= 1024) {
        this.mobileView = false;
      } else {
        this.mobileView = true;
      }
    },
  },
};
</script>
<style>
.v-application--is-ltr
  .v-list-group--no-action
  > .v-list-group__items
  > .v-list-item {
  padding-left: 16px !important;
}

.v-application--is-ltr
  .v-list-group--no-action
  > .v-list-group__items
  > .v-list-item {
  padding-left: 16px !important;
}
</style>
