import api from "./api.js";
export default {
  data: () => ({
    valid: false,
    dialog: false,
    selectedObject: { id: -1 },
    loading: true,
    deleteDialog: false,
    reference: null,
    clicked: false,
    snackbar: false,
    text: "",
    search: "",
    id: null,
    json_fields: {
      Id: "id",
      Status: "statusText",
      Reference: "reference",
      "Assigned To": "driverFullName",
    },

    NormalRules: [(v) => !!v || "Field is required"],
    myCurrentData: [],
    ddata: [],
    headers: [
      {
        text: "Status",
        align: "center",
        value: "drivers[0]",
      },
      {
        text: "Reference",
        align: "center",
        value: "reference",
      },

      {
        text: "Actions",
        value: "actions",
        align: "center",
      },
    ],
  }),
  mounted() {
    api
      .getbuses()
      .then((res) => {
        this.loading = false;
        if (res.data.responseCode === 1) {
          res.data.data.forEach((element) => {
            switch (element.taken) {
              case "1":
                element.statusText = "Assigned";
                element.driverFullName =
                  element.drivers[0].fname + " " + element.drivers[0].lname;
                break;
              case "0":
                element.statusText = "No Assigned";
                element.driverFullName = "N/A";
                break;

              default:
                break;
            }
            this.ddata.push(element);
          });
        }
      })
      .catch((error) => {
        this.error = error;
        this.loading = false;
        this.errorDialog = true;
      });
  },
  methods: {
    getFiltered(e) {
      this.myCurrentData = e;
      //output the filtered items
    },
    onDelete(item) {
      this.selectedObject = item;
      this.deleteDialog = true;
    },
    showDialog(item) {
      this.valid = true;
      if (item == null) {
        this.selectedObject = { id: -1 };
        this.reference = null;
        this.id = null;
      } else {
        this.selectedObject = item;
        this.reference = item.reference;

        this.id = item.id;
      }
      this.dialog = true;
    },
    agreeOnDelete() {
      this.clicked = true;
      this.loading = true;
      api
        .archive(this.selectedObject.id)
        .then((res) => {
          this.loading = false;
          if (res.data.responseCode === 1) {
            this.deleteDialog = false;
            this.clicked = false;
            var index = this.ddata.indexOf(this.selectedObject);
            this.$delete(this.ddata, index);
            this.text = "Vehicle deleted succesfully.";
            this.snackbar = true;
          } else {
            console.log(res.data.responseMessage);
          }
        })
        .catch((error) => {
          this.loading = false;
          console.log(error);
          this.errorDialog = true;

          this.deleteDialog = false;
          this.clicked = false;
        });
    },
    save() {
      this.clicked = true;
      if (!this.$refs.form.validate()) {
        this.clicked = false;
        return;
      }
      this.loading = true;
      if (this.selectedObject.id != -1) {
        api
          .save(this.id, this.reference)
          .then((res) => {
            this.loading = false;
            this.dialog = false;
            this.clicked = false;
            if (res.data.responseCode === 1) {
              var index = this.ddata.indexOf(this.selectedObject);
              this.ddata[index].reference = this.reference;

              this.text = "Vehicle updated successfully";
              this.snackbar = true;
            } else {
              this.text = res.data.responseMessage;
              this.snackbar = true;
            }
          })
          .catch((error) => {
            this.text = error;
            this.snackbar = true;
            this.loading = false;
            this.dialog = false;
            this.clicked = false;
          });
      } else {
        api
          .add(this.reference)
          .then((res) => {
            this.loading = false;
            this.dialog = false;
            this.clicked = false;
            if (res.data.responseCode === 1) {
              this.ddata.push({
                id: res.data.data.id,
                reference: this.reference,
                statusText: "No Assigned",
                driverFullName: "N/A",
                drivers: [],
              });
              this.text = "Vehicle added successfully.";
              this.snackbar = true;
            } else {
              this.text = res.data.responseMessage;
              this.snackbar = true;
            }
          })
          .catch((error) => {
            this.text = error;
            this.snackbar = true;
            this.loading = false;
            this.dialog = false;
            this.clicked = false;
          });
      }
    },
  },
};
