import api from './api.js';
export default {
    data: () => ({
        valid: false,
        dialog: false,
        selectedObject: { id: -1 },
        loading: true,
        deleteDialog: false,
        title: null,
        clicked: false,
        text: '',
        snackbar: false,
        search: '',
        id: null,
        myCurrentData: [],
        json_fields: {
            'Id': 'id',
            'Name': 'title',
        },

        NormalRules: [
            v => !!v || 'Field is required',

        ],
        data: [],
        headers: [
        {
            text: 'Name',
            align: 'center',
            value: 'title'
        },


        {
            text: 'Actions',
            value: 'actions',
            align: 'center',
        },
        ],

    }),
    mounted() {
        api.gettypes().then((res) => {
            this.loading = false;
            if (res.data.responseCode === 1) {
                this.data = res.data.data;
            }
        }).catch((error) => {
            this.error = error;
            this.loading = false;
            this.errorDialog = true;
        });
    },
    methods: {
        getFiltered(e) {
            this.myCurrentData = e;
            //output the filtered items
        },
        onDelete(item) {
            this.selectedObject = item;
            this.deleteDialog = true;
        },
        showDialog(item) {
            this.valid = true;


            if (item == null) {
                this.selectedObject = { id: -1 };
                this.title = null;

                this.id = null;
            } else {
                this.selectedObject = item;
                this.title = item.title;

                this.id = item.id;
            }
            this.dialog = true;


        },
        agreeOnDelete() {
            this.clicked = true;

            this.loading = true;
            api.archive(this.selectedObject.id).then((res) => {
                this.loading = false;
                if (res.data.responseCode === 1) {
                    this.deleteDialog = false;
                    this.clicked = false;
                    var index = this.data.indexOf(this.selectedObject);
                    this.$delete(this.data, index);
                    this.text = 'Trip type deleted succesfully.';
                    this.snackbar = true;
                } else {
                    
                    this.text = res.data.responseMessage;
                    this.snackbar = true;

                }
            }).catch((error) => {
                this.loading = false;
                console.log(error);
                this.errorDialog = true;
            });
        },
        save() {
            this.clicked = true;
            if (!this.$refs.form.validate()) {
                this.clicked = false;
                return;
            }
            this.loading = true;
            if (this.selectedObject.id != -1) {
                api.save(this.id, this.title)
                    .then((res) => {
                        this.loading = false;
                        this.dialog = false;
                        this.clicked = false;
                        if (res.data.responseCode === 1) {
                            var index = this.data.indexOf(this.selectedObject);
                            this.data[index].title = this.title;


                            this.text = 'Trip type updated successfully.';
                            this.snackbar = true;
                        } else {
                            this.text = res.data.responseMessage;
                            this.snackbar = true;
                        }
                    }).catch((error) => {
                        this.text = error;
                        this.snackbar = true;
                        this.loading = false;
                        this.dialog = false;
                        this.clicked = false;
                    });
            } else {
                api.add(this.title)
                    .then((res) => {
                        this.loading = false;
                        this.dialog = false;
                        this.clicked = false;
                        if (res.data.responseCode === 1) {
                            this.data.push({
                                id: res.data.data.id,
                                title: this.title,

                            });
                            this.text = 'Trip type added successfully.';
                            this.snackbar = true;

                        } else {
                            this.text = res.data.responseMessage;
                            this.snackbar = true;

                        }
                    }).catch((error) => {
                        this.text = error;
                        this.snackbar = true;
                        this.loading = false;
                        this.dialog = false;
                        this.clicked = false;
                    });
            }
        },

    },
}
