var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"wrapper"},[_c('div',{staticClass:"wrappperheader"},[_c('div',{staticClass:"headertitle"},[_c('p',{staticClass:"pagetitle"},[_c('v-icon',{staticClass:"mainpageicon",attrs:{"fab":"","large":""}},[_vm._v(_vm._s(this.$route.meta.mainicon))]),_vm._v(" "+_vm._s(this.$route.meta.title)+" ")],1)]),_c('div',{staticClass:"headerbuttons"},[_c('div',{staticClass:"btn1"},[_c('export-excel',{attrs:{"data":_vm.myCurrentData,"fields":_vm.json_fields,"worksheet":"Origin","name":"Origins.xls"}},[_c('v-btn',[_vm._v(" Export ")])],1)],1)])]),_c('v-card',[_c('v-card-title',[_c('v-spacer'),_c('v-row',{staticClass:"pa-0 ma-0"},[_c('v-col',{attrs:{"cols":_vm.Column}},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-col',{attrs:{"cols":_vm.Column}},[_c('div',[_c('v-menu',{ref:"fmenu",attrs:{"close-on-content-click":false,"return-value":_vm.fdate,"transition":"scale-transition","offset-y":"","min-width":"auto"},on:{"update:returnValue":function($event){_vm.fdate=$event},"update:return-value":function($event){_vm.fdate=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"From","hide-details":"","append-icon":"mdi-calendar","readonly":""},model:{value:(_vm.fdate),callback:function ($$v) {_vm.fdate=$$v},expression:"fdate"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.fmenu),callback:function ($$v) {_vm.fmenu=$$v},expression:"fmenu"}},[_c('v-date-picker',{attrs:{"no-title":"","scrollable":"","color":"red"},model:{value:(_vm.fdate),callback:function ($$v) {_vm.fdate=$$v},expression:"fdate"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.fmenu = false}}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.$refs.fmenu.save(_vm.fdate), _vm.fetch()}}},[_vm._v(" OK ")])],1)],1)],1)])],1)],1),_c('v-data-table',{attrs:{"loading":_vm.loading,"loading-text":"Loading... Please wait","headers":_vm.headers,"items":_vm.data,"search":_vm.search,"sort-by":"id","sort-desc":true},on:{"current-items":_vm.getFiltered},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{on:{"click":function($event){return _vm.onDelete(item)}}},[_vm._v("delete")])]}},{key:"item.user_type",fn:function(ref){
var item = ref.item;
return [(item.user_type === 0)?_c('span',[_vm._v(" Admin ")]):_c('span',[_vm._v(" Driver ")])]}},{key:"item.user_id",fn:function(ref){
var item = ref.item;
return [(item.user_id === null)?_c('span',[_vm._v(" N.A")]):_c('span',[_vm._v(" "+_vm._s(item.user_id)+" ")])]}},{key:"item.extra",fn:function(ref){
var item = ref.item;
return [(item.extra === null)?_c('span',[_vm._v(" None ")]):_c('span',[_vm._v(" "+_vm._s(item.extra)+" ")])]}}],null,true)},[_c('v-progress-linear',{directives:[{name:"show",rawName:"v-show",value:(true),expression:"true"}],attrs:{"slot":"progress","color":"red","indeterminate":""},slot:"progress"})],1)],1),_c('v-dialog',{attrs:{"max-width":"400"},model:{value:(_vm.deleteDialog),callback:function ($$v) {_vm.deleteDialog=$$v},expression:"deleteDialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5"},[_vm._v(" Confirmation Required ")]),_c('v-card-text',[_vm._v("Are you sure you want to delete this User, This action cannot be undone!")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{staticClass:"btn1",attrs:{"text":""},on:{"click":function($event){_vm.deleteDialog = false}}},[_vm._v(" Cancel ")]),_c('v-btn',{staticClass:"btn",attrs:{"disabled":_vm.clicked,"text":""},on:{"click":_vm.agreeOnDelete}},[_vm._v(" Confirm ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }