import api from "./api.js";

export default {
  data: () => ({
    show1: false,
    warning: false,
    valid: true,
    password: "",
    passwordRules: [
      (v) => !!v || "Password is required",
      (v) => (v && v.length <= 10) || "Name must be less than 10 characters",
    ],
    email: "",
    emailRules: [
      (v) => !!v || "E-mail is required",
      (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
    ],
  }),

  methods: {
    validate() {
      if (this.$refs.form.validate()) {
        api
          .login(this.email, this.password)
          .then((res) => {
            if (res.data.responseCode === 1) {
              var id = res.data.data.id;
              var email = res.data.data.email;
              var tenant_id = res.data.data.tenant_id;
              var is_super_admin = res.data.data.is_super_admin;

              this.$store.dispatch("login", {
                id,
                email,
                tenant_id,
                is_super_admin,
              });

              this.$router.push({
                name: "Dashboard",
              });
            } else {
              this.warning = true;
              console.log(res.data.responseMessage);
            }
          })
          .catch((error) => {
            // this.errors.push(error);
            console.log(error);
          });
      }
    },
  },
};
