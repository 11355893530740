import axios from "@/plugins/http.service.js";

export default {
  gettypes() {
    const tenant_id = localStorage.getItem("cooriroo_tenant_id");

    return axios.post(`type/get-types?tenant_id=${tenant_id}`);
  },
  archive(id) {
    var data = {
      id: id,
    };
    return axios.post("type/archive", data);
  },
  save(id, title) {
    const tenant_id = localStorage.getItem("cooriroo_tenant_id");

    var data = {
      id: id,
      title: title,
      tenant_id,
    };

    return axios.post("type/save", data);
  },
  add(title) {
    const tenant_id = localStorage.getItem("cooriroo_tenant_id");

    var data = {
      title: title,
      tenant_id,
    };

    return axios.post("type/add", data);
  },
};
