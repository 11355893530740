import axios from "axios";

//
let API_ENDPOINT = process.env.VUE_APP_API_ENDPOINT;
let config = {
    baseURL: `${API_ENDPOINT}`,
};

const httpClient = axios.create(config);

export default httpClient;
