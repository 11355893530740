import api from './api.js';
// import the component and the necessary extensions
import {
    TiptapVuetify,
    Heading,
    Bold,
    Italic,
    Strike,
    Image,
    Underline,
    Code,
    BulletList,
    OrderedList,
    ListItem,
    Link,
    Blockquote,
    HardBreak,
    HorizontalRule,
    History,
} from 'tiptap-vuetify'

export default {
    components: { TiptapVuetify },
    data() {
        return {
            id: null,
            title_en: null,
            title_de: null,
            myCurrentData: [],
            slag: null,
            content_en: ``,
            clicked: false,
            content_de: ``,
            extensions: [
                History,
                Blockquote,
                Link,
                Underline,
                Image,
                Strike,
                Italic,
                ListItem,
                BulletList,
                OrderedList, [Heading, {
                    options: {
                        levels: [1, 2, 3]
                    }
                }],
                Bold,
                Code,
                HorizontalRule,
                HardBreak,

            ],

            snackbar: false,
            text: null,
            NormalRules: [
                v => !!v || 'Field is required',
            ],
            istranslation: false,
            selectedObject: {},
            valid: true,
            dialog: false,
            contents: [],
            loading: false,
            search: '',
            json_fields: {
                'Id': 'id',
                'Title-En': 'title_en',
                'Title_De': 'title_de',
                'Slag': 'slag',
                'Content': 'content_en',
                'Content-De': 'content_de',

            },
            headers: [
            {
                text: 'Title-En',
                align: 'center',
                value: 'title_en'
            },
            {
                text: 'Title-De',
                align: 'center',
                value: 'title_de'
            },
            {
                text: 'Slag',
                align: 'center',
                value: 'slag'
            },
            {
                text: 'Actions',
                value: 'actions',
                align: 'center',
            },

            ],
        }
    },
    methods: {
        getFiltered(e) {
            this.myCurrentData = e;
            //output the filtered items
        },
        showDialog(item, flag) {
            this.istranslation = flag;
            this.valid = true;
            this.selectedObject = item;
            this.title_en = item.title_en;
            this.id = item.id;
            this.slag = item.slag;
            this.title_de = item.title_de;
            this.content_en = item.content_en;
            this.content_de = item.content_de;
            this.dialog = true;
        },
        save() {
            this.clicked = true;
            if (!this.$refs.form.validate()) {
                this.clicked = false;
                return false;
            }

            this.loading = true;
            if (this.istranslation) {
                api.transalte(this.id, this.title_de, this.content_de)
                    .then((res) => {
                        this.loading = false;
                        this.dialog = false;
                        this.clicked = false;
                        if (res.data.responseCode === 1) {
                            var index = this.contents.indexOf(this.selectedObject);
                            this.contents[index].title_de = this.title_de;
                            this.contents[index].content_de = this.content_de;
                            this.text = 'Content updated successfully.';
                            this.snackbar = true;
                        } else {
                            this.text = res.data.responseMessage;
                            this.snackbar = true;
                        }
                    }).catch((error) => {
                        this.text = error;
                        this.snackbar = true;
                        this.loading = false;
                        this.dialog = false;
                        this.clicked = false;
                    });
            } else {
                api.save(this.id, this.title_en, this.content_en)
                    .then((res) => {
                        this.loading = false;
                        this.dialog = false;
                        this.clicked = false;
                        if (res.data.responseCode === 1) {
                            var index = this.contents.indexOf(this.selectedObject);

                            this.contents[index].title_en = this.title_en;
                            this.contents[index].content_en = this.content_en;
                            this.text = 'Content updated successfully.';
                            this.snackbar = true;
                        } else {
                            this.text = res.data.responseMessage;
                            this.snackbar = true;
                        }
                    }).catch((error) => {
                        this.text = error;
                        this.snackbar = true;
                        this.loading = false;
                        this.dialog = false;
                        this.clicked = false;
                    });
            }
        }
    },

    mounted() {
        api.GetContents().then((res) => {
            this.loading = false;
            if (res.data.responseCode === 1) {
                this.contents = res.data.data;
            }
        }).catch((error) => {
            this.error = error;
            this.loading = false;

        });
    },
}