import axios from "@/plugins/http.service.js";

export default {
  getdestinations() {
    const tenant_id = localStorage.getItem("cooriroo_tenant_id");

    return axios.get(`destination/get-destinations?tenant_id=${tenant_id}`);
  },
  add(name, tenant_id, lat, lon) {
    var data = {
      name: name,
      tenant_id: parseInt(tenant_id),
      lat,
      lon,
    };
    return axios.post("destination/add", data);
  },
  save(id, name, lat, lon) {
    var data = {
      id: id,
      name: name,
      lat,
      lon,
    };
    return axios.post("destination/save", data);
  },
  archive(id) {
    var data = {
      id: id,
    };
    return axios.post("destination/archive", data);
  },
};
