import axios from "@/plugins/http.service.js";

export default {
  getbuses() {
    const tenant_id = localStorage.getItem("cooriroo_tenant_id");
    return axios.get(`bus/get-buses?tenant_id=${tenant_id}`);
  },

  archive(id) {
    var data = {
      id: id,
    };
    return axios.post("bus/archive", data);
  },

  save(id, reference) {
    const tenant_id = localStorage.getItem("cooriroo_tenant_id");

    var data = {
      id: id,
      reference: reference,
      tenant_id,
    };

    return axios.post("bus/save", data);
  },

  add(reference) {
    const tenant_id = localStorage.getItem("cooriroo_tenant_id");

    var data = {
      reference: reference,
      tenant_id,
    };

    return axios.post("bus/add", data);
  },
};
