import api from "./api.js";
export default {
  data: () => ({
    zoom: 9,
    center: { lat: 25.354826, lng: 51.183884 },
    window_open: false,
    info_marker: null,
    infowindow: { lat: 10, lng: 10.0 },
    trip_window: false,
    selected_driver: null,
    driverName: null,
    info: null,
    selectedMarker: null,
    driver_id: -1,
    drivers: [],
    filter: null,
    items: [],
    filters: [
      { text: "Late", value: 3 },
      { text: "Early", value: 4 },
      { text: "Finished", value: 2 },
      { text: "Waiting", value: 5 },
    ],
    markers: [],
    trip_markers: [],
    valid: false,
    dialog: false,
    selectedObject: null,
    loading: true,
    driverInfo: false,
    search: "",
    snackbar: false,
    text: "",
    isrequired: true,
    noRules: [],
    passwordRules: [
      (v) => !!v || "Password is required",
      (v) =>
        (v && v.length <= 10) || "Password must be less than 10 characters",
      (v) => (v && v.length >= 4) || "Minimum 4 Characters",
    ],

    emailRules: [
      (v) => !!v || "E-mail is required",
      (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
    ],
    NormalRules: [(v) => !!v || "Field is required"],

    show1: false,
    show2: false,
    email: null,
    fname: null,
    lname: null,
    p1: null,
    myCurrentData: [],
    p2: null,
    tdata: [],
    adata: [],
    today: null,
    json_fields: {
      Id: "id",
      "First Name": "fname",
      "Last Name": "lname",
      Email: "email",
    },
    theaders: [
      {
        text: "Driver Name",
        value: "driver.fullname",
        align: "center",
      },
      {
        text: "Status",
        value: "flag",
        align: "center",
      },
      {
        text: "Actions",
        value: "actions",
        align: "center",
      },
    ],
  }),
  async mounted() {
    var myCurrentDate = new Date();
    this.today = myCurrentDate
      .toISOString()
      .replace("T", " ")
      .substr(0, 19);
    await api
      .GetDriversRecentLocations(this.today)
      .then((res) => {
        // this.loading = false;
        if (res.data.responseCode === 1) {
          res.data.data.forEach((element) => {
            if (element.locations.length != 0)
              this.markers.push({
                id: element.locations[0].id,
                icon: require("@/assets/images/icons/bus.svg"),
                info: element.locations[0].date_and_time,
                driverName: element.fname + " " + element.lname,
                driver_id: parseInt(element.id),
                position: {
                  lat: parseFloat(element.locations[0].lat),
                  lng: parseFloat(element.locations[0].lon),
                },
              });
          });
        }
      })
      .catch((error) => {
        this.error = error;
        // this.loading = false;
        this.errorDialog = true;
      });

    setInterval(() => {
      if (this.$route.name == "Dashboard")
        api
          .GetDriversRecentLocations(this.today)
          .then((res) => {
            if (res.data.responseCode === 1) {
              res.data.data.forEach((element) => {
                if (element.locations.length != 0) {
                  this.markers.push({
                    id: element.locations[0].id,
                    icon: require("@/assets/images/icons/bus.svg"),
                    info: element.driver.locations[0].date_and_time,
                    driverName:
                      element.driver.fname + " " + element.driver.lname,
                    driver_id: parseInt(element.driver_id),
                    position: {
                      lat: parseFloat(element.driver.locations[0].lat),
                      lng: parseFloat(element.driver.locations[0].lon),
                    },
                  });
                }
              });
            }
          })
          .catch((error) => {
            this.error = error;
            // this.loading = false;
            this.errorDialog = true;
          });
    }, 10000);

    await api
      .gettrips()
      .then((res) => {
        this.loading = false;
        if (res.data.responseCode === 1) {
          res.data.data.forEach((element) => {
            element.driver = {
              fullname: element.driver.fname + " " + element.driver.lname,
              id: element.driver.id,
            };
            element.warehouse = {
              title: element.warehouse.title,
              id: element.warehouse.id,
            };

            if (element.started_date != null) {
              if (
                this.convertUTCDateToLocalDate(element.started_date) >
                this.convertUTCDateToLocalDate(element.start_date)
              ) {
                // Late
                element.status = 1;
              } else {
                element.status = 2; // Early
              }
            } else {
              element.status = 0; // Not yet
            }

            element.flag =
              element.flag == 0
                ? "Not Picked"
                : element.ended_date
                ? "Delivered"
                : "Picked";

            element.ended_date =
              element.ended_date != null
                ? this.convertUTCDateToLocalDate(element.ended_date)
                : element.started_date != null
                ? "On route..."
                : "Waiting...";
            element.started_date =
              element.started_date == null
                ? "Waiting..."
                : this.convertUTCDateToLocalDate(element.started_date);

            element.start_date = this.convertUTCDateToLocalDate(
              element.start_date
            );
            element.created_date = this.convertUTCDateToLocalDate(
              element.created_date
            );

            const lat = element.destination?.lat
              ? element.destination?.lat
              : element.destination_lat;

            const lon = element.destination?.lon
              ? element.destination?.lon
              : element.destination_lon;

            this.tdata.push(element);
            this.trip_markers.push({
              ...element,
              position: {
                lat: parseFloat(lat),
                lng: parseFloat(lon),
              },
            });
          });
        }
      })
      .catch((error) => {
        this.text = error;
        this.snackbar = true;
        this.loading = false;
        this.errorDialog = true;
      });
  },
  computed: {
    filteredItems() {
      return this.tdata.filter((i) => {
        return !this.filter || i.status === this.filter;
      });
    },
    column() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 12;
        case "sm":
          return 12;
        case "md":
          return 6;
        case "lg":
          return 6;
        case "xl":
          return 6;
      }
    },
  },
  methods: {
    showDialog(item) {
      this.selectedObject = item;
      this.driverInfo = true;
    },
    convertUTCDateToLocalDate(utcDate) {
      if (utcDate) {
        const date = new Date(utcDate);
        var newDate = new Date(
          date.getTime() + date.getTimezoneOffset() * 60 * 1000
        );

        var offset = date.getTimezoneOffset() / 60;
        var hours = date.getHours();

        newDate.setHours(hours - offset);

        return newDate.toLocaleString();
      }
    },
    getFiltered(e) {
      this.myCurrentData = e;
    },
    focus(driverid) {
      this.selectedMarker = null;
      for (let index = 0; index < this.markers.length; index++) {
        var element = this.markers[index];
        if (element.driver_id === parseInt(driverid)) {
          this.selectedMarker = element;
        }
      }
      if (this.selectedMarker != null) {
        this.$refs.map.$mapPromise.then((map) => {
          map.panTo(this.selectedMarker.position);
        });
      }
      if (this.selectedMarker == null) {
        this.text = "Driver has no registered location.";
        this.snackbar = true;
      }
    },

    openWindow(index, driverName, info) {
      this.infowindow = this.markers[index].position;
      this.driver_id = this.markers[index].driver_id;
      this.driverName = driverName;
      this.info = this.convertUTCDateToLocalDate(info);
      this.window_open = true;
    },

    openTripWindow(index, driverName, info) {
      this.infowindow = this.trip_markers[index].position;
      this.driver_id = this.trip_markers[index].driver_id;
      this.driverName = driverName;
      this.info = info;
      this.trip_window = true;
    },
  },
  getSiteIcon() {
    return require("@/assets/images/icons/bus.svg");
  },
};
