import axios from "@/plugins/http.service.js"

export default {
    login(email, pass) {
        var data = {
            'email': email,
            'password': pass
        };

        return axios.post("admin/login", data);
    },
};