import axios from "@/plugins/http.service.js";

export default {
  getaccidents() {
    const tenant_id = localStorage.getItem("cooriroo_tenant_id");

    return axios.post(`accident/get-latest-accidents?tenant_id=${tenant_id}`);
  },
  gettrips() {
    const tenant_id = localStorage.getItem("cooriroo_tenant_id");

    var data = {
      key: "admin",
      tenant_id,
    };
    return axios.post("trip/get-trips", data);
  },
  GetDriversRecentLocations(date) {
    const tenant_id = localStorage.getItem("cooriroo_tenant_id");

    var data = {
      date: date,
    };
    return axios.post(
      `driver/get-drivers-recent-location?tenant_id=${tenant_id}`,
      data
    );
  },

  save(id, fname, lname, email, password) {
    var data = {
      id: id,
      email: email,
      fname: fname,
      lname: lname,
      password: password,
    };
    return axios.post("admin/save", data);
  },

  archive(id) {
    var data = {
      id: id,
    };
    return axios.post("admin/archive", data);
  },
};
